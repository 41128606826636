import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { interiorTheme } from 'common/theme/interior';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Interior/Navbar';

import Heroarea from 'containers/Interior/Heroarea';
import Feature from 'containers/Interior/Feature';
import AboutUs from 'containers/Interior/AboutUs';
import Counters from 'containers/Interior/Counters';
import Benefits from 'containers/Interior/Benefits';
import Slider from 'containers/Interior/Slider';
import Contact from 'containers/Interior/Contact';

import ReviewSlider from 'containers/Interior/ReviewSlider';
import Footer from 'containers/Interior/Footer';
import { ResetCSS } from 'common/assets/css/style';
import 'common/assets/css/main-page.css'
import {
  GlobalStyle,
  InteriorWrapper,
  ContentWrapper,
} from 'containers/Interior/interior.style';
import Seo from 'components/seo';

const Interior = () => {
  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo title="Radiance Hair Studio | Hair Wig Solution's" />
        <ResetCSS />
        <GlobalStyle />

        {/* Start writing your markup from here. */}
        <InteriorWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <Heroarea />
            <Feature />
            <AboutUs />
            <Counters />
            <Benefits />
            
            <ReviewSlider />
            <Slider />
            <Contact />
          </ContentWrapper>
          <Footer />
        </InteriorWrapper>
        {/* End of markup section. */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Interior;
