import React, { useContext } from 'react';
import { Container } from './navbar.style';
import { useStaticQuery, graphql } from 'gatsby';
import logo from 'common/assets/image/interior/radiance-logo.png';

import { Icon } from 'react-icons-kit';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';

import Logo from 'common/components/UIElements/Logo';
import HamburgMenu from 'common/components/HamburgMenu';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import { DrawerContext } from 'common/contexts/DrawerContext';
import Copyright from '../Copyright';


const Navbar = ({ navbarStyle, logoStyle }) => {

  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        menuData {
          path
          label
          offset
        }
        socialProfile {
          id
          icon
          link
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };
  
  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="/"
          logoSrc={logo}
          title="Interior"
          logoStyle={logoStyle}
          className="logoWrapper"
        />
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '25px' }}>

        <span className="call-now"> Free Consultation <br /><i className="fa fa-phone-square white fa-fw align-middle" style={{ fontSize: '23px' }}></i> <a href="tel:+919958585649"> +91-99585-85649</a> 
        </span>

        </div>
        <Drawer
            width="420px"
            placement="right"
            drawerHandler={<HamburgMenu />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <button
              type="button"
              className={state.isOpen ? 'active' : ''}
              onClick={toggleHandler}
              aria-label="drawer toggle button"
            >
              <Icon icon={androidClose} />
            </button>
            <ScrollSpyMenu
              menuItems={data.interiorJson.menuData}
              drawerClose={true}
              offset={-100}
            />
            <Copyright data={data.interiorJson.socialProfile} />
          </Drawer>
      </Container>
    </NavbarWrapper>
  );
};



export default Navbar;
