import React, { useState } from "react";
import heroImage1 from '../../../common/assets/image/interior/gallery/before.png';
import heroImage2 from '../../../common/assets/image/interior/gallery/after.png';
import heroBack from '../../../common/assets/image/interior/hero_back.jpg';
import inFlag from '../../../common/assets/image/interior/in.svg';
import SectionWrapper, {
  Container,
} from './heroarea.style';

const Heroarea = () => {

    // State to store form input values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can access the form data in the 'formData' state object
    console.log(formData);
    // Add your code to submit the form data to your backend or perform other actions here
  };
  
  return (
    <SectionWrapper id="heroArea" data-src={heroBack}>
      <Container>
            <div className='heroarea-row'>
                <div className='heroLeftarea'>
                    <p className='tagLine'>HAIR WIG SOLUTION’S START ONLY @ ₹ 6999</p>
                    <h1>100% Natural & Completely Undetectable.</h1>
                    <p className='subhead'>GETTING RID OF BALDNESS DOESN'T HAPPEN <br />BY LUCK, IT DOES HAPPEN BY APPOINTMENT</p>
                    <hr />
                    <form onSubmit={handleSubmit} className="heroFormArea">
                        <div className="columnarea">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Enter your name"
                            required
                        />

                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Enter your email"
                            required
                        />

                        </div>
                        <div className="columnarea">
                      <div className="phonewithcoun">
                        <span className="fixedPhoneFlag"><img src={inFlag} alt="india flag"/></span>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="Phone number"
                            required
                        />
                      </div>

<select className="city-dropdown">
    <option value="delhi">Select city</option>
    <option value="delhi">Delhi</option>
    <option value="mumbai">Mumbai</option>
    <option value="bangalore">Bangalore</option>
    <option value="chennai">Chennai</option>
    <option value="kolkata">Kolkata</option>
    <option value="hyderabad">Hyderabad</option>
    <option value="pune">Pune</option>
    <option value="ahmedabad">Ahmedabad</option>
  </select>
  

                        </div>
                        
                        <div className="columnareaFull">
                            <button type="submit">Submit</button>
                        </div>
                        
                    </form>
                </div>
                <div className='heroRightarea'>
                  <div className="heroleft">
                    <img src={ heroImage1 } alt="radiance" />
                    <h3 className="imageBeforeAfterhead">Before</h3>
                  </div>
                  <div className="heroleft">
                    <h3 className="imageBeforeAfterhead">After</h3>
                    <img src={ heroImage2 } alt="radiance" />
                  </div>
                    
                </div>
            </div>
      </Container>
    </SectionWrapper>
  );
};

export default Heroarea;
