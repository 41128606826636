import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Container from 'common/components/UI/Container';
import male1bafore from '../../../common/assets/image/interior/male1-before.jpg';
import male1after from '../../../common/assets/image/interior/male1-after-2.jpg';
import male2before from '../../../common/assets/image/interior/male2-before.jpg';
import male2after from '../../../common/assets/image/interior/male2-after.jpg';
import femalebefore from '../../../common/assets/image/interior/femal-before.jpg';
import femaleafter from '../../../common/assets/image/interior/femal-after.jpg';

import { SectionHeader } from '../interior.style';
import Fade from 'react-reveal/Fade';
import Heading from 'common/components/Heading';
import SectionWrapper from './slider.style';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Slider() {

  const swiperBreakpoints = {
    // When viewport width is less than or equal to 767px (typical mobile size)
    767: {
      slidesPerView: 4, // Display 2 slides on mobile
      spaceBetween: 10, // Adjust as needed
    },
    // When viewport width is greater than 767px (larger screens)
    768: {
      slidesPerView: 4, // Display 1 slide on larger screens
      spaceBetween: 20, // Adjust as needed
    },
  };


  return (
    <SectionWrapper id="successStories">
      <Fade bottom>
        <SectionHeader>
          <Heading as="h5" content="Life-changing results" />
          <Heading content="Before and after success stories" />
        </SectionHeader>
      </Fade>
    <Container className="homeSlider">
        <Swiper
        spaceBetween={10} // Adjust as needed
        slidesPerView={2} // Display 4 slides per view
        navigation
        breakpoints={swiperBreakpoints}
        // pagination={{ clickable: true }}
        
        >
        <SwiperSlide>
            {/* Content for the first slide */}
            <img src={male1bafore} alt="Slide 1" />
            <h3>Before</h3>
        </SwiperSlide>
        <SwiperSlide>
            {/* Content for the second slide */}
            <img src={male1after} alt="Slide 2" />
            <h3>After</h3>
        </SwiperSlide>
        <SwiperSlide>
            {/* Content for the third slide */}
            <img src={male2before} alt="Slide 3" />
            <h3>Before</h3>
        </SwiperSlide>
        <SwiperSlide>
            {/* Content for the fourth slide */}
            <img src={male2after} alt="Slide 4" />
            <h3>After</h3>
        </SwiperSlide>
        <SwiperSlide>
            {/* Content for the third slide */}
            <img src={femalebefore} alt="Slide 5" />
            <h3>Before</h3>
        </SwiperSlide>
        <SwiperSlide>
            {/* Content for the third slide */}
            <img src={femaleafter} alt="Slide 6" />
            <h3>After</h3>
        </SwiperSlide>
        </Swiper>
    </Container>
    </SectionWrapper>
  );
}

export default Slider;
