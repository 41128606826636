import React from 'react';
import SectionWrapper, {
  Container
} from './counters.style';

const Counters = () => {
  
  return (
    <SectionWrapper id="counters">
      <Container>
      <div className="achivment"> 
        <div> 
            <span className="fw7">10 Years</span> 
            <span className="d-block">in Hair Solution</span> 
        </div>
        <div> 
            <span className="fw7">15 +</span> 
            <span className="d-block">Different Hair Solution</span> 
        </div>
        <div> 
            <span className="fw7">50,000 +</span> 
            <span className="d-block">Happy Customers</span> 
        </div>
        <div> 
            <span className="fw7">5.0 <i className="fa fa-star"></i></span> 
            <span className="d-block">Google Star Rating</span> 
        </div>
       </div>
      </Container>
    </SectionWrapper>
  );
};

export default Counters;
