import React from 'react';
import Container from 'common/components/UI/Container';
import ContactForm from 'containers/Interior/ContactForm';
import ContactWrapper from './contact.style';

function Contact() {
 

  return (
    <ContactWrapper id="contact">
      <Container width="1330px" className="footerwrapscoltop">
            <div className='footerLeftop'>
                <div className='contactiInfo'>
                 <h1>Notice hair loss?</h1>
                  <p style={{ color: '#333333' }}>Get in touch with our experts now</p>
                </div>
            </div>
            <div className='footerRightop'>
                <ContactForm />
                 </div>
        </Container>
    </ContactWrapper>
  );
}

export default Contact;