import React, { useState } from 'react';
import inFlag from '../../../common/assets/image/interior/in.svg';

const formStyle = {

  color: 'black',

};

const labelStyle = {
  display: 'block',
  marginBottom: '5px',
};

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    location: '',
    subject: '',
    officeHour: 'Select an option',
    city: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission here
    console.log(formData);
  };

  return (
    <div>
      <h3 style={{color: '#333333', textAlign: 'center'}}>Stay Updated. Subscribe to our newsletter</h3>
      <form style={formStyle} onSubmit={handleSubmit} id="footerContactForm">
        <div className='formRow'>
            <div className='formControlss'>
            <label htmlFor="name" style={labelStyle}>
                Name:
            </label>
            <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required
            />
            </div>
            <div className='formControlss'>
            <label htmlFor="phone" style={labelStyle}>
                Phone Number:
            </label>
            <span class="fixedPhoneFlag"><img src={inFlag} alt="india flag"/></span>
            <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="e.g., +123456789"
                required
            />
            </div>

            <div className='formControlss'>
            <label htmlFor="email" style={labelStyle}>
                Email:
            </label>
            <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="you@example.com"
                required
            />
            </div>
        </div>
        <div className='formRow'>
            <div className='formControlss'>
            <label htmlFor="location" style={labelStyle}>
                Select Location:
            </label>
            <select
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Select an option"
            >
                <option value="">Select an option</option>
                {/* Add location options here */}
            </select>
            </div>
            <div className='formControlss'>
            <label htmlFor="subject" style={labelStyle}>
                Subject:
            </label>
            <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject of your inquiry"
            />
            </div>
        </div>
        <div className='formRow'>
            <div className='formControlss'>
            <label htmlFor="officeHour" style={labelStyle}>
                Office Hour:
            </label>
            <select
                id="officeHour"
                name="officeHour"
                value={formData.officeHour}
                onChange={handleChange}
            >
                <option value="Select an option">Select an option</option>
                {/* Add office hour options here */}
            </select>
            </div>
            <div className='formControlss'>
            <label htmlFor="city" style={labelStyle}>
                City:
            </label>
            <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="Your City"
            />
            </div>
        </div>

        <div className='formControlss'>
          <label htmlFor="description" style={labelStyle}>
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={4}
            placeholder="Enter a description"
          ></textarea>
        </div>

        <div className='formControlss'>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;