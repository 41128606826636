import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Container from 'common/components/UI/Container';
import reviewImg1 from '../../../common/assets/image/interior/testimo/t1.jpg';
import reviewImg2 from '../../../common/assets/image/interior/testimo/t2.jpg';
import reviewImg3 from '../../../common/assets/image/interior/testimo/t3.jpg';

import { SectionHeader } from '../interior.style';
import Fade from 'react-reveal/Fade';
import Heading from 'common/components/Heading';
import SectionWrapper from './reviewSlider.style';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

function ReviewSlider() {

  return (
    <SectionWrapper id="successStories">
      <Fade bottom>
        <SectionHeader>
          <Heading as="h5" content="Testimonials" />
          <Heading content="Thus Spoke our Customers" />
        </SectionHeader>
      </Fade>
    <Container className="homeSlider">
        <Swiper
        spaceBetween={10} // Adjust as needed
        slidesPerView={1} // Display 4 slides per view
        autoplay={{ delay: 3000 }}
        navigation
        // pagination={{ clickable: true }}
        
        >
          <SwiperSlide>
            <div className='reviewWrap'>
              <p>After having my hair loss, I visited many places but my experience was bad almost everywhere, Then a friend of mine referred Radiance was where to go & he was so right! My hair turned out perfect, just the way I wanted it. My stylist, Gufran was very experienced and informative. He recommended products and was very knowledgeable but not a hard sell. I would recommend Radiance to anyone who asks....</p>
              <div className='reviewHead'>
                <img src={reviewImg1} alt="Review 1" />
                <div className='contetnwrap'>
                <h4>Sandeep Kumar</h4>
                <span>New Delhi</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='reviewWrap'>
                <p>Radiance is Simply amazing! Relaxing atmosphere, friendly and helpful staff, excellent quality and outstanding service! There was never any sort of pressure to purchase anything (as opposed to most hair-clinics out there) and they were eager to help in any way possible. My stylist was very professional, helpful and very sociable. He always made sure I was comfortable and guided me through every single step. He also listened clearly to all my styling requests. Truly exceptional service!Strongly Recommend this place...</p>
                <div className='reviewHead'>
                  <img src={reviewImg2} alt="Review 2" />
                  <div className='contetnwrap'>
                  <h4>Manoj Noida</h4>
                  <span>UP</span>
                  </div>
                </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='reviewWrap'>
                <p>Well!! It was my first visit to Radiance Hair Studio, I find about them on the Internet ... I was looking for Good Hair Bonding solutions for a long time and visited most of the popular studios in City but I felt some of them are too costly if the quality is good and others are just a useless one. But, the price and quality of the product of Radiance offering very good and competitive. They have very good Technicians and I am satisfied with the demo with different hairstyles they have shown. I'm Completely satisfied with Radiance.</p>
                <div className='reviewHead'>
                  <img src={reviewImg3} alt="Review 3" />
                  <div className='contetnwrap'>
                  <h4>Rishi Arora</h4>
                  <span>Tilak Nagar, New Delhi</span>
                  </div>
                </div>
            </div>
          </SwiperSlide>
        </Swiper>
    </Container>
    </SectionWrapper>
  );
}

export default ReviewSlider;
